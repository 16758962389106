import anime from 'animejs/lib/anime.es';

function membershipTabs() {
    const activeClass = 'is-expand';
    const attrWithParams = 'data-membership';

    const switchers = document.querySelectorAll('.js-membership-btn');
    const tabs = document.querySelectorAll('.js-membership-tab');

    if(switchers.length && tabs.length ) {
        const abjActive = {
            swItem: document.querySelectorAll('.js-membership-btn ' + activeClass),
            swTab: document.querySelectorAll('.js-membership-tab ' + activeClass)
        };

        const setActive = function(swItem){

            const switcherFor =  swItem.getAttribute(attrWithParams);

            if(abjActive.swItem && abjActive.swItem.classList) {
                abjActive.swItem.classList.remove(activeClass);
            }
            swItem.classList.add(activeClass);
            abjActive.swItem = swItem;

            [].forEach.call(tabs, function(swTab){
                const swTabFor = swTab.getAttribute(attrWithParams);
                if(swTabFor === switcherFor) {
                    swTab.classList.add(activeClass);
                    abjActive.swTab = swTab;
                } else {
                    swTab.classList.remove(activeClass);
                }
            });
        };


        if(!abjActive.swItem.length || !abjActive.swTab.length ) {
            setActive(switchers[0]);
        }

        [].forEach.call(switchers, function(swItem ) {

            swItem.addEventListener('click', (e) => {
                setActive(swItem);
            });

        });


    }
}

function inlineTabs() {
    const activeClass = 'is-expand';
    const attrWithParams = 'data-inline';

    const switchers = document.querySelectorAll('.js-inline-btn');
    const tabs = document.querySelectorAll('.js-inline-tab');

    let swItemPrevious;

    if(switchers.length && tabs.length ) {
        const abjActive = {
            swItem: document.querySelectorAll('.js-inline-btn ' + activeClass),
            swTab: document.querySelectorAll('.js-inline-tab ' + activeClass)
        };

        const setActive = function(swItem){

            if (swItemPrevious) {
                swItemPrevious.querySelector('.m-tabs-inline__switchers-icon-text').textContent = 'View';
                swItemPrevious.querySelector('.m-tabs-inline__switchers-icon-text').removeAttribute('style');
                swItemPrevious.querySelector('.line-minus').style.display = 'none';
                swItemPrevious.querySelector('.line-plus').style.display = 'block';
                swItemPrevious.setAttribute('aria-expanded', 'false');
            }

            const switcherFor =  swItem.getAttribute(attrWithParams);

            if(abjActive.swItem && abjActive.swItem.classList) {
                abjActive.swItem.classList.remove(activeClass);
            }


            abjActive.swItem = swItem;

            // clicked on the same item
            if (swItemPrevious == swItem) {

                swItem.querySelector('.m-tabs-inline__switchers-icon-text').textContent = 'View';
                swItem.querySelector('.m-tabs-inline__switchers-icon-text').removeAttribute('style');
                swItem.querySelector('.line-minus').style.display = 'none';
                swItem.querySelector('.line-plus').style.display = 'block';
                swItem.setAttribute('aria-expanded', 'false');

                if(swItem.classList.contains('toggling-close')) {
                    swItem.querySelector('.m-tabs-inline__switchers-icon-text').textContent = 'Minimize';
                    swItem.querySelector('.m-tabs-inline__switchers-icon-text').style.color = '#649cd3';
                    swItem.querySelector('.line-plus').style.display = 'none';
                    swItem.querySelector('.line-minus').style.display = 'block';
                    swItem.setAttribute('aria-expanded', 'true');
                    swItem.classList.add('is-expand')
                    swItem.nextElementSibling.classList.add('is-expand');
                    swItem.classList.remove('toggling-close')
                } else {
                    swItem.classList.add('toggling-close')
                    swItem.classList.remove('is-expand')
                    swItem.nextElementSibling.classList.remove('is-expand');
                }

            } else {

                swItem.classList.add(activeClass);
                [].forEach.call(tabs, function(swTab){
                    const swTabFor = swTab.getAttribute(attrWithParams);
                    if(swTabFor === switcherFor) {
                        swTab.classList.add(activeClass);
                        abjActive.swTab = swTab;
                    } else {
                        swTab.classList.remove(activeClass);
                    }
                });

                swItem.querySelector('.m-tabs-inline__switchers-icon-text').textContent = 'Minimize';
                swItem.querySelector('.m-tabs-inline__switchers-icon-text').style.color = '#649cd3';
                swItem.querySelector('.line-plus').style.display = 'none';
                swItem.querySelector('.line-minus').style.display = 'block';
                swItem.setAttribute('aria-expanded', 'true');
            }

            // set current item to previous item for next click
            swItemPrevious = swItem;
        };


        if(!abjActive.swItem.length || !abjActive.swTab.length ) {
            setActive(switchers[0]);
        }

        [].forEach.call(switchers, function(swItem ) {

            swItem.addEventListener('click', (e) => {
                setActive(swItem);
            });

        });


    }
}


document.addEventListener('DOMContentLoaded', () => {
    membershipTabs();
    inlineTabs();
});